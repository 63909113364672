import { Card, CardContent, Theme, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Throbber } from '.';
import { ErrorContext } from '../context/ErrorContext';
import { useAuth } from '../hooks/useAuth';
import { useIsDev } from '../hooks/useIsDev';
import { useQueryStringParams } from '../hooks/useQueryStringParams';
import { useStripeState } from '../hooks/useStripeState';
import * as api from '../store/api-client';
import { acceptInvitation } from '../store/api-client';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { Organization } from '../store/models';
import { _plan, getPlanByName } from '../store/slices/editorSlice';
import { _location } from '../store/slices/locationSlice';
import { _organization, createNewOrganization } from '../store/slices/organizationSlice';
import loadingGif from '../theme/liiingo_loop.gif';
import { colors } from '../theme/palette';
import { BlockingModal } from './BlockingModal';
import { InfoLink } from './InfoLink';
import { liiingoFailure } from './LiiingoFailureSnackbar';

// lazy loading this because it's large
const PasswordStrengthBar = lazy(() => import('react-password-strength-bar'));

export type Props = {
	onboardingRoute: string;
	organizationId: string;
	referrer?: string;
};

type FormValues = {
	firstName: string;
	lastName: string;
	orgName: string;
	email: string;
	password: string;
	password2: string;
	confirmationCode: string;
	termsOfService: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		display: 'flex',
		flexGrow: 1,
	},
	fillHeight: {
		flexGrow: 1,
	},
	centerMyContentPlease: {
		textAlign: 'center',
	},
	topOfForm: {
		height: 'min-content',
	},
	bottomOfForm: {
		flexGrow: 1,
		paddingTop: 20,
		alignItems: 'flex-end',
	},
	horizontalLine: {
		border: `1px solid ${colors.graySecondaryLight}`,
	},
	orSignInWithText: {
		lineHeight: 1,
	},
	verifyButton: {
		marginTop: 20,
	},
	pageTitle: {
		paddingBottom: theme.spacing(4),
	},
	paperTitle: {
		color: colors.grayDark,
		paddingBottom: 8,
		paddingLeft: 10,
	},
	paper: {
		paddingLeft: 20,
	},
	passwordRoot: {
		position: 'relative',
	},
	passwordRequirement: {
		position: 'absolute',
		top: 100,
		right: 0,
		left: 0,
		zIndex: 1,
		minWidth: 380,
		padding: 10,
		borderRadius: 10,
		backgroundColor: colors.pureWhite,
		boxShadow: '0px 1px 4px rgba(24, 27, 32, 0.2)',
	},
	img1: {
		marginBottom: '-100px',
		// position: 'relative',
		// left: 0,
		// top: 0,
		// marginTop: 'auto',
		// marginBottom: 'auto',
		// width: 'auto',
	},
	loading_buffer: {
		left: '-10%',
		paddingLeft: 'auto',
		paddingRight: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '120%',
	},
}));

export const SignUpForm = ({ onboardingRoute, organizationId, referrer }: Props) => {
	const { handleError } = useContext(ErrorContext);
	const [errorMessage, setErrorMessage] = useState('');
	const [showLoading, setShowLoading] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const urlParams = useQueryStringParams();
	const organization: Organization = useAppSelector(_organization);
	const setOrg = bindActionCreators(createNewOrganization, dispatch);
	const location = useAppSelector(_location);
	const fetchPlanByName = bindActionCreators(getPlanByName, dispatch);
	const plan = useAppSelector(_plan);
	const [open, setOpen] = React.useState(false);
	const { login } = useAuth();
	const isDev = useIsDev();

	// option 1: pass plan and billing in url.
	// e.g. /signUp?plan=Essentials&billing=monthly
	const planName = urlParams.get('plan');
	const billing = urlParams.get('billing');

	// option 2: pass priceId in url.
	// e.g. /signUp?priceId=price_1JsTgmC8BvMYvOOAwGfs0CIn
	const priceInUrl = urlParams.get('priceId');
	const [priceId, setPriceId] = useState(priceInUrl || null);

	// couponId is optional and can be used with either of the above options
	const couponId = urlParams.get('couponId');

	// email is optional and can be used with either of the above options
	const emailInUrl = urlParams.get('email'); // eslint-disable-line @typescript-eslint/no-unused-vars

	// trialDays is optional and can be used with either of the above options, only valid in dev mode
	let trialDays = urlParams.get('trialDays') || null;

	const invitationId = urlParams.get('invitationId');
	const { createCustomerSubscription } = useStripeState();

	const { register, errors, formState, getValues, handleSubmit, watch } = useForm({
		mode: 'onChange',
		defaultValues: {
			firstName: 'rFirstName',
			lastName: 'rLastName',
			orgName: 'My Business',
			email: '',
			password: '',
			password2: '',
			confirmationCode: '',
			termsOfService: true,
		},
	});
	watch('password');

	// useEffect to send a GA4 event when the user lands on the sign up page
	useEffect(() => {
		// GOOGLE ANALYTICS
		window.globalThis.gtag('event', 'sign_up_landing', {
			value: 1,
		});
	}, []);

	// useEffect to detect if a location is set in Redux, and if so, hard referesh the page to clear the store out
	useEffect(() => {
		if (location) {
			window.location.reload();
		}
	}, [location]);

	// useEffect to get the plan and set the priceId based on url parameters
	useEffect(() => {
		// if plan not fetched yet, and we have a plan name, fetch it
		if (!plan && planName) {
			fetchPlanByName(planName);
			console.log('plan: ', plan, 'planName: ', planName);
		}
	}, [fetchPlanByName, plan, planName]);

	// useEffect to set the priceId based on fetched plan and billing url parameter
	useEffect(() => {
		// if plan object has been fetched...
		if (plan && !priceId) {
			if (billing === 'monthly') {
				// if billing url param is monthly
				setPriceId(plan.monthlyPriceId); //set the priceId to the monthly priceId
			} else if (billing === 'annual') {
				//if the billing url param is annual
				setPriceId(plan.annualPriceId); // set the priceId to the yearly priceId
			}
		}
	}, [plan, billing, priceId]);

	// if url doesn't have what we need, send them to the price page
	useEffect(() => {
		// if there is no priceId in the url...
		if (!priceInUrl) {
			// and if the route is 'essentials'... and there is no plan or billing info in the url...
			if (onboardingRoute === 'essentials' && (!planName || !billing)) {
				// reroute to pricing page
				window.location.href = 'https://www.liiingo.com/pricing';
			}
		}
	}, [onboardingRoute, planName, billing, priceInUrl]);

	// handle when form is submitted
	const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
		data.firstName = data.firstName || 'rFirstName';
		data.lastName = data.lastName || 'rLastName';
		data.orgName = data.orgName || 'My Business';
		//show the loading pop-up
		setShowLoading(true);

		try {
			// create a liiingo organization via PHP
			let org = await api.createNewOrganization({
				name: data.orgName || 'My Business',
				email: data.email,
				password: data.password,
			});

			// set the org in redux by dispatching action
			setOrg(org);

			// if we dont get an org, close the modal and pop an error
			if (!org.id) {
				setShowLoading(false);
				liiingoFailure('Error creating organization.');
				setErrorMessage('Error creating organization.');
				return;
			}

			// GOOGLE ANALYTICS
			window.globalThis.gtag('event', 'Sign_up', {
				plan: planName,
				billing: billing,
				referrer: referrer,
				category: 'Sign_up',
				action: 'Sign_up',
				value: 1,
				// first_name: data.firstName,
				// last_name: data.lastName,
				// organization_name: data.orgName,
				// email: data.email,
				// label: data.email,
			});

			if (!isDev) {
				// if in dev mode, allow trial days to be set from a url param,
				// otherwise nuke the param if someone happens to set it production
				trialDays = null;
			}

			// need to get a valid token here, before calling the next endpoint
			const token = await login(data.email, data.password);

			// if no token, close the modal and pop an error message
			if (!token) {
				setShowLoading(false);
				liiingoFailure('Error getting PHP token.');
				setErrorMessage('Error getting PHP token.');
				return;
			}

			// CREATE STRIPE CUSTOMER AND SUBSCRIPTION
			let { stripeCustomer, stripeSubscription } = await createCustomerSubscription(
				data.email,
				priceId,
				couponId,
				trialDays
			);

			// if no stripe customer or subscription, close the modal and pop an error message
			if (!stripeCustomer || !stripeSubscription) {
				setShowLoading(false);
				liiingoFailure('Error creating Stripe account.');
				setErrorMessage('Error creating Stripe account.');
				return;
			}

			// create a clone of the templateId
			let result = await api.cloneApp({
				templateId: plan.templateId,
				name: `My Liiingo`,
				contactName: `${data.firstName} ${data.lastName}`,
				email: data.email,
				companyName: data.orgName,
			});

			// if nothing returned from cloneApp api call, close the modal and pop an error message
			if (!result) {
				setShowLoading(false);
				liiingoFailure('Error creating new app.');
				setErrorMessage('Error creating new app.');
				return;
			}

			setShowLoading(false);
			history.push(`dashboard`);
		} catch (error) {
			// if anything throws an unexpected error, we safely close the modal and assume all is lost.
			setShowLoading(false);
			handleError(JSON.stringify(error));
			setErrorMessage(JSON.stringify(error));
			console.error('error cloning template app for new organization');
			// TODO: if something fails here, we need to clean up.
		}
	};

	const validationMessages = {
		requiredMessage: 'This field is required',
		passwordLength: 'Your password must be at least 8 characters',
		passwordsMatch: 'Password fields must match',
		emailContainsSlash:
			'Email must not contain a "/" for security reasons. Contact support@liiingo.com if this affects you.',
	};

	// useEffect to accept invitation
	useEffect(() => {
		// if the user is invited by an organization, accept the invitation
		if (invitationId && organization) {
			acceptInvitation(invitationId, organization?.id);
		}
	}, [invitationId, organization]);

	// REGEX for:
	// Contains a minimum of 8 characters
	// Contains at least one lowercase letter(a–z)
	// Contains at least one uppercase letter(A–Z)
	// Contains at least one number(0–9)
	// Contains at least one special character(!@#$ %)
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/; //Dont worry about understanding this, but ask if you're curious

	// function to validate against Regex
	const validatePassword = (data: string) => {
		if (!data) {
			return 'Password is required';
		}

		if (!data.match(passwordRegex)) {
			// return "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one number, and one special character (!@#$%)";
			setOpen(true);
			return 'The password requirements are not met.';
		}
		setOpen(false);
		return undefined;
	};

	return (
		<>
			<BlockingModal open={showLoading} fullPage={false}>
				<Card>
					<CardContent>
						<Grid container justifyContent="center">
							<Grid
								item
								xs={12}
								style={{ display: 'flex', marginBottom: 16 }}
								className={classes.centerMyContentPlease}
							>
								<Typography variant="h2">
									<p>Your account is being created</p>
									<img src={loadingGif} alt="logo" className={classes.img1} />
									<p>It should be ready in 30 seconds!</p>
									<LinearProgress variant="query" className={classes.loading_buffer} />
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</BlockingModal>

			<Typography variant="h5" align="center" className={classes.pageTitle}>
				Welcome to Liiingo!
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
				<Grid container direction="row" className={classes.fillHeight}>
					<Grid container item direction="row" xs={12} spacing={2} className={classes.topOfForm}>
						<Grid item xs={12}>
							{errorMessage && <Alert severity="error">{errorMessage}</Alert>}
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Email"
								fullWidth
								variant="outlined"
								type="email"
								name="email"
								// value={emailInUrl || ''}
								error={!!errors.email}
								helperText={errors?.email?.message}
								inputRef={register({
									required: validationMessages.requiredMessage,
									validate: (data) => {
										if (data.includes('/')) {
											return validationMessages.emailContainsSlash;
										}
										return undefined;
									},
								})}
							/>
						</Grid>
						<Grid container item spacing={2}>
							<Grid item xs={12} sm={6}>
								<ClickAwayListener
									mouseEvent="onMouseDown"
									touchEvent="onTouchStart"
									onClickAway={() => setOpen(false)}
								>
									<div className={classes.passwordRoot}>
										<TextField
											label="Password"
											fullWidth
											variant="outlined"
											type="password"
											name="password"
											error={!!errors.password}
											helperText={errors?.password?.message}
											inputRef={register({
												required: validationMessages.requiredMessage,
												validate: validatePassword,
											})}
										/>
										<Suspense fallback={<Throbber />}>
											<PasswordStrengthBar password={getValues('password')} minLength={8} />
										</Suspense>
										{open ? (
											<div className={classes.passwordRequirement}>
												<Typography variant="h6" className={classes.paperTitle}>
													Password Requirements
												</Typography>
												<Typography className={classes.paper} variant="body2" color="inherit">
													Contains a minimum of 8 characters
												</Typography>
												<Typography className={classes.paper} variant="body2" color="inherit">
													Contains at least one lowercase letter (a–z)
												</Typography>
												<Typography className={classes.paper} variant="body2" color="inherit">
													Contains at least one uppercase letter (A–Z)
												</Typography>
												<Typography className={classes.paper} variant="body2" color="inherit">
													Contains at least one number (0–9)
												</Typography>
												<Typography className={classes.paper} variant="body2" color="inherit">
													Contains at least one special character (!@#$%)
												</Typography>
											</div>
										) : null}
									</div>
								</ClickAwayListener>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									label="Confirm Password"
									fullWidth
									variant="outlined"
									type="password"
									name="password2"
									error={!!errors.password2}
									helperText={errors?.password2?.message}
									inputRef={register({
										required: validationMessages.requiredMessage,
										validate: () =>
											getValues('password') !== getValues('password2')
												? validationMessages.passwordsMatch
												: undefined,
									})}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								data-cy="loginButton"
								disabled={
									!!errors.password ||
									!!errors.password2 ||
									getValues('password') !== getValues('password2') ||
									formState.isSubmitting
								} // !! => https://dev.to/sanchithasr/what-is-the-double-bang-operator-in-javascript-4i3h
								fullWidth
							>
								Create Account
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								By clicking “Create Account”, you acknowledge that you have read, understood, and agree
								to the{' '}
								<InfoLink to="https://www.liiingo.com/terms-of-service/">
									Subscription Agreement
								</InfoLink>{' '}
								and <InfoLink to="https://www.liiingo.com/privacy-policy">Privacy Policy.</InfoLink>
							</Typography>
						</Grid>
					</Grid>
					<Grid container item direction="row" xs={12} className={classes.bottomOfForm}>
						<Grid item xs={12} className={classes.centerMyContentPlease}>
							<Typography variant="body2">
								I have an account. <InfoLink to="sign-in">Sign In</InfoLink>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</>
	);
};
