import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import App from './App';
import TrialDaysWidget from './components/TrialDays/TrialDaysWidget';
import { ErrorContextProvider } from './context/ErrorContext';
import { OnboardingFlowContextProvider } from './context/OnboardingFlowContext';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from './services/bugsnag';
import store, { history } from './store/store';
import { theme } from './theme';
import { colors } from './theme/palette';

if (window.location.pathname.includes('//')) {
	const newPath = window.location.pathname.replace(/\/\//g, '/');
	window.location.assign(newPath);
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StyledToaster() {
	return (
		<Toaster
			position="top-center"
			reverseOrder={true}
			gutter={8}
			containerClassName=""
			containerStyle={{}}
			toastOptions={{
				// Define default options
				className: '',
				duration: 3000,
				style: {
					background: '#363636',
					color: '#fff',
				},
				// icon: {
				//   color: colors.greenDark,
				//   marginRight: 15,
				// },
				loading: {},
				custom: {},
				success: {
					style: {
						borderWidth: 1,
						borderRadius: 6,
						borderColor: colors.greenDark,
						borderStyle: 'solid',
						backgroundColor: colors.pureWhite,
						boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 10,
						height: 42,
					},
				},
				error: {
					style: {
						borderWidth: 1,
						borderRadius: 6,
						borderColor: colors.redDark,
						color: '#000000',
						borderStyle: 'solid',
						backgroundColor: colors.redLight,
						boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 10,
						height: 42,
					},
				},
			}}
		/>
	);
}
ReactDOM.render(
	<ErrorBoundary>
		<StyledToaster />
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ConnectedRouter history={history}>
					{/* place ConnectedRouter under Provider */}
					<Elements stripe={stripePromise}>
						<OnboardingFlowContextProvider>
							<ErrorContextProvider>
								<App />
								<TrialDaysWidget />
							</ErrorContextProvider>
						</OnboardingFlowContextProvider>
					</Elements>
				</ConnectedRouter>
			</ThemeProvider>
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
