//----------- IMPORTS -----------//
import { Theme, colors, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _location } from '../../store/slices/locationSlice';
import { FlatButton } from '../Buttons';
import { TranslateDialog } from '../TranslateDialog';

// ----------- STYLES -----------//
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonContainer: {
			flex: 'auto',
			display: 'flex',
			justifyContent: 'flex-end',
			margin: 'auto',
			marginRight: 20,
		},
		button: {
			height: '2rem',
			backgroundColor: colors.common.white,
			color: '#295AFA',
			fontFamily: 'Roboto',
			fontSize: 14,
			'&:hover': {
				backgroundColor: '#295AFA33',
			},
			'&:active': {
				backgroundColor: '#295AFA',
				color: colors.common.white,
			},
		},
	})
);

//----------- TYPE DEFINITIONS FOR COMPONENT'S PROPS -----------//
export type TranslateButtonProps = {
	// open: boolean;
};

//----------- FUNCTION COMPONENT -----------//
export const TranslateButton = (props: TranslateButtonProps) => {
	//----------------------- FUNCTION VARIABLES -----------------------//
	// const { open } = { ...props };
	const classes = useStyles();
	const location = useAppSelector(_location);
	const [open, setOpen] = useState(false);

	//----------------------- USE-EFFECT AND OTHER HOOKS -----------------------//
	// useEffect(() => {
	// 	// console.log('useEffect() called');
	// }, []);

	//----------------------- EVENT HANDLERS/ MISC HELPER FUNCTIONS -----------------------//
	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick') {
			return;
		}
		setOpen(!open);
	};

	//----------------------- RETURNED JSX -----------------------//
	return location.supportedLanguages.length > 1 ? (
		<div className={classes.buttonContainer}>
			{open && <TranslateDialog open={open} handleClose={handleClose} />}
			<FlatButton
				className={classes.button}
				variant="text"
				color="primary"
				onClick={() => setOpen(!open)}
				disableRipple
			>
				Translate
			</FlatButton>
		</div>
	) : null;
};
