/* eslint-disable react-hooks/exhaustive-deps */
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { _selectedTopic, _topics, getQrs } from '../../../store/slices/topicSlice';
import { colors } from '../../../theme/palette';
import { CLOSED_QR_DIALOG, CLOSED_QR_HOVER } from '../../../util/constants';
import { FlatButton } from '../../Buttons/FlatButton';
import { LiiingoTempDrawer } from '../../LiiingoTempDrawer';
import { QRDialog } from './QRDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Grid,
	Paper,
	Select,
	Slider,
	TextField,
	Switch,
} from '@material-ui/core';
import { Keyframes } from '../../../theme/Keyframes';
import { LiiingoDismissableTip } from '../../LiiingoDismissableTip';
import { QRColorPickerPopover } from './QRColorPickerPopover';
import { _selectedLocation, updateLocation } from '../../../store/slices/locationSlice';
import axiosInstance from '../../../store/api-client';
import * as lodash from 'lodash';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { MenuItem } from '@mui/material';
import { CustomImageUploading } from './CustomImageUploading';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		editorBox: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			marginLeft: 10,
			marginRight: 10,
			overflow: 'hidden',
		},
		buttonBox: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 65,
			background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #92A0AC',

			marginLeft: -10,
			marginRight: -10,
			position: 'sticky',
			bottom: 0,
		},
		buttonBoxFocused: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			height: 65,
			background: 'blue',

			marginLeft: -10,
			marginRight: -10,
			position: 'sticky',
			bottom: 0,
		},

		button: {
			height: 40,
			marginRight: 30,
		},
		previewBox: {
			display: 'flex',
			justifyContent: 'center',
			margin: 'auto',
		},
		previewWrapper: {
			display: 'flex',
			jusiifyContent: 'center',
			alignItems: 'center',
			width: '35%',
			background: 'white',
			paddingBottom: 10,
			order: 2,
			// when the viewport is 768px wide or less width is 100%
			'@media (max-width: 768px)': {
				width: '100%',
			},
		},
		desc: {
			marginBottom: 40,
			color: '#181B20',
		},
		colorBox: {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: 10,
		},
		menu: {
			// when the viewport is 768px wide or wider items will be side by side, otherwise they will be stacked
			'@media (min-width: 768px)': {
				flexDirection: 'row',
				minHeight: '99%',
			},
			display: 'flex',
			position: 'relative',
			left: 0,
			top: 0,
			minHeight: '90%',
			justifyContent: 'space-between',
			flexDirection: 'column',
		},
		optionBox: {
			// when the viewport is less than 768px wide width is 100%
			'@media (max-width: 768px)': {
				width: '100%',
				height: 'fit-content !important',
			},
			overflowY: 'scroll',
			height: '90% ',
			width: '65%',
		},
		alert: {
			margin: 8,
			backgroundColor: colors.hotPurpleAccent20,
		},
		icon: {
			color: colors.hotPurpleAccent,
		},
		dismissableTip: {
			marginTop: 40,
		},
		link: {
			color: 'blue',
			textDecoration: 'none',
			fontWeight: 700,
		},
		accordionWrapper: {
			maxWidth: '100%',
		},
		accordionSummry: {
			'& .MuiAccordionSummary-content': {
				margin: '12px 0',
				display: 'flex',
				flexGrow: 1,
				transition: 'margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				justifyContent: 'center',
			},
		},
		accordionRoot: {
			'& .MuiAccordion-root.Mui-expanded': {
				margin: '0 0',
			},
		},
		heading: {
			paddingLeft: 5,
			fontSize: theme.typography.pxToRem(17),
		},
		title: {
			padding: 5,
			textAlign: 'left',
			paddingLeft: 20,
		},
		customizeButtonBox: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			paddingRight: 20,
			spaceBetween: 20,
		},
		customizeButton: {
			minWidth: '100%',
		},
		customizeButtonFocused: {
			minWidth: '100%',
			background: 'blue',
			color: 'white',
		},
		container: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridGap: theme.spacing(3),
		},
		paper: {
			textAlign: 'center',
			whiteSpace: 'nowrap',
			margin: theme.spacing(1),
			overflow: 'auto',
		},
		sliderDisplayInfo: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			padding: 10,
		},
		dropZone: {
			width: '100%',
			padding: 15,
			margin: 'auto',
			border: '1px solid #ccc',
			borderRadius: '.25rem',
			cursor: 'pointer',
		},
		marginTopBottom: {
			marginBottom: 20,
			marginTop: 20,
		},
		dropDownSelect: {
			width: '100%',
			marginBottom: 40,
		},
		frameDisplayBox: {
			width: 150,
			height: 150,
			padding: 30,
			cursor: 'pointer',
		},

		frameDisplayBoxFocused: {
			width: 150,
			height: 150,
			padding: 30,
			cursor: 'hand',
			background: 'blue',
			borderRadius: '5%',
		},
		drawerPaper: {
			width: '100%',
			marginLeft: 10,
			marginRight: 10,
		},
		switch: {
			textAlign: 'justify',
			marginTop: 20,
		},
	})
);

export type QREditorProps = {
	open: boolean;
	logo: string;
	primary: string;
	secondary: string;
	setOpen: (open: boolean) => void;
	handleUpdate: () => void;
};

export const QREditor = (props: QREditorProps) => {
	const { open, setOpen } = {
		...props,
	};
	const [openDialog, setOpenDialog] = useState(false);
	const [isDrag, setIsDrag] = useState(false);
	const [loading, setLoading] = useState(false);
	const selectedTopic = useAppSelector(_selectedTopic);
	const topics = useAppSelector(_topics);
	const dispatch = useAppDispatch();
	const getNewQrs = bindActionCreators(getQrs, dispatch);
	const [dirty, setDirty] = useState(false);
	const locationData = useAppSelector(_selectedLocation);
	const [qrprops, setQRProps] = useState({
		...locationData?.qrcodeProperties,
	});
	const [qrCode, setQrCode] = useState(selectedTopic?.qr);
	const [frameTextTemp, setFrameTextTemp] = useState(qrprops.frame_text);
	const [expanded, setExpanded] = useState<string | false>('panel1');
	const classes = useStyles();
	const setupdateLocation = bindActionCreators(updateLocation, dispatch);
	const [qrCodeLogo, setQrCodeLogo] = useState(null);
	const [qrCodeBackground, setQrCodeBackground] = useState(null);

	const handleOpen = (open: boolean) => {
		if (!open && !localStorage.getItem(CLOSED_QR_DIALOG) && dirty) {
			setOpenDialog(true);
		} else {
			setOpen(open);
			// on open use the selected topic's qr code until an edit is made
			setQrCode(selectedTopic?.qr);
		}
	};

	const newFormPayload = (qrprops: any, qrCodeLogo: any, qrCodeBackground: any, uploadImage: boolean) => {
		const formdata = new FormData();

		Object.keys(qrprops).forEach((key) => {
			formdata.append(key, qrprops[key]);
		});
		let id = selectedTopic?.id;
		if (topics) {
			id = id ?? topics[0]?.id;
		}
		formdata.append('url', process.env.REACT_APP_LIIINGO_WEBAPP_URL + '/topic/' + id);
		formdata.append('type', 'url');

		if (qrCodeLogo && qrprops.qr_code_logo_activate) {
			formdata.append('qr_code_logo', qrCodeLogo);
		}
		if (qrCodeBackground && qrprops.qr_code_background_activate) {
			formdata.append('qr_code_background', qrCodeBackground);
		}
		if (uploadImage) {
			formdata.append('uploadImage', String(uploadImage));
			formdata.append('locationId', locationData.id);
			delete formdata['url'];
		}
		return formdata;
	};

	const handleSave = () => {
		const formdata = newFormPayload(qrprops, qrCodeLogo, qrCodeBackground, true);
		axiosInstance.post('/exhibit/qr', formdata).then((d) => {
			console.log('Returned QR', d.data);
			setupdateLocation({ qrcodeProperties: d.data.qrProps });
			getNewQrs();
			setOpen(false);
		});
	};

	const handleDragStart = () => {
		if (!isDrag) setIsDrag(true);
	};

	const handleDragStop = () => {
		if (isDrag) setIsDrag(false);
	};

	window.addEventListener('dragover', handleDragStart, false);
	window.addEventListener('dragleave', handleDragStop, false);
	window.addEventListener('drop', handleDragStop, false);

	const handleFrameTextSizeChange = (event, newValue) => {
		setQRProps({ ...qrprops, frame_text_size: newValue });
	};

	const handleBgImgTransparencyChange = (event, newValue) => {
		setQRProps({ ...qrprops, qr_code_background_transparency: newValue });
	};

	const handleBgColorTransparencyChange = (event, newValue) => {
		setQRProps({ ...qrprops, background_color_transparency: newValue });
	};
	const handleLogoSizeChange = (event, newValue) => {
		setQRProps({ ...qrprops, qr_code_logo_size: newValue });
	};

	const handleEcc = (e) => {
		setQRProps({ ...qrprops, ecc: e.target.value });
	};

	const makePreview = useCallback(
		lodash.debounce(
			(qrprops, qrCodeLogo, qrCodeBackground) => {
				const formdata = newFormPayload(qrprops, qrCodeLogo, qrCodeBackground, false);
				axiosInstance.post('/exhibit/qr', formdata).then((response) => {
					if (response?.data) {
						// setPreviewId(response?.data?.id);
						setQrCode(response?.data.svg);
					} else {
						console.log('Bad Image');
					}
					setLoading(false);
				});
			},
			1000,
			{ trailing: true }
		),
		[]
	);

	useDidMountEffect(() => {
		setDirty(true);
		setLoading(true);
		makePreview(qrprops, qrCodeLogo, qrCodeBackground);
		// eslint-disable-next-line
	}, [qrprops, qrCodeLogo, qrCodeBackground]);

	const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<LiiingoTempDrawer title="Design Your QR Code" id="QREditor" open={open} setOpen={handleOpen}>
			{/* Leave without saving? */}
			<QRDialog
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
				}}
				closeDrawer={() => {
					setOpen(false);
				}}
			/>
			<Box className={classes.editorBox}>
				<Typography className={classes.desc} variant="body2">
					Add a logo, colors, and much more to make your QR code recognizable to your audience. <br></br>
					<a
						className={classes.link}
						href="https://support.liiingo.com/question-category/share"
						target="_blank"
						rel=" noreferrer"
					>
						Learn more in our Help Center.{' '}
					</a>
				</Typography>

				{/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
				<Box className={classes.menu}>
					<Keyframes name="fadeOut" _0={{ opacity: 1 }} _100={{ opacity: 0.3 }} />
					<Keyframes name="fadeIn" _0={{ opacity: 0.3 }} _100={{ opacity: 1 }} />

					<div className={classes.previewWrapper}>
						<Box className={classes.previewBox}>
							<div>
								<img
									width={250}
									height={250}
									src={qrCode ? qrCode : selectedTopic?.qr}
									alt="QR Code Preview"
									style={
										loading
											? {
													animation: 'fadeOut 8s ease-out',
													maxWidth: '100%',
													objectFit: 'contain',
											  }
											: {
													animation: 'fadeIn 1.5s ease-in',
													maxWidth: '100%',
													objectFit: 'contain',
											  }
									}
								></img>
							</div>
						</Box>
					</div>

					<Box component="div" className={classes.optionBox}>
						<Box className={classes.accordionWrapper}>
							<Accordion
								defaultExpanded
								expanded={expanded === 'panel1'}
								onChange={handleAccordionChange('panel1')}
								className={classes.accordionRoot}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
									className={classes.accordionSummry}
								>
									<i className="fa-solid fa-wand-magic-sparkles"></i>{' '}
									<Typography className={classes.heading}>Style</Typography>
								</AccordionSummary>
								<Typography className={classes.title}>
									<i className="fa-solid fa-mortar-pestle"></i> Style
								</Typography>
								<AccordionDetails>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.style === 'square'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, style: 'square' });
													}}
												>
													Square
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.style === 'dot'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, style: 'dot' });
													}}
												>
													Dot
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.style === 'round'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, style: 'round' });
													}}
												>
													Rounded
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.style === 'heart'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, style: 'heart' });
													}}
												>
													Heart
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.style === 'diamond'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, style: 'diamond' });
													}}
												>
													Diamond
												</FlatButton>
											</Paper>
										</Grid>
									</Grid>
								</AccordionDetails>
								<Typography className={classes.title}>
									<i className="fa-solid fa-circle"></i> Inner eye style
								</Typography>
								<AccordionDetails>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.inner_eye_style === 'square'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, inner_eye_style: 'square' });
													}}
												>
													Square
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												{' '}
												<FlatButton
													className={
														qrprops?.inner_eye_style === 'dot'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, inner_eye_style: 'dot' });
													}}
												>
													Dot
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.inner_eye_style === 'rounded'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, inner_eye_style: 'rounded' });
													}}
												>
													Rounded
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.inner_eye_style === 'flower'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, inner_eye_style: 'flower' });
													}}
												>
													Flower
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.inner_eye_style === 'leaf'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, inner_eye_style: 'leaf' });
													}}
												>
													Leaf
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.inner_eye_style === 'diamond'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, inner_eye_style: 'diamond' });
													}}
												>
													Diamond
												</FlatButton>
											</Paper>
										</Grid>
									</Grid>
								</AccordionDetails>
								<Typography className={classes.title}>
									<i className="fa-solid fa-circle-dot"></i> Outer eye style
								</Typography>
								<AccordionDetails>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.outer_eye_style === 'square'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, outer_eye_style: 'square' });
													}}
												>
													Square
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												{' '}
												<FlatButton
													className={
														qrprops?.outer_eye_style === 'circle'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, outer_eye_style: 'circle' });
													}}
												>
													Circle
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.outer_eye_style === 'rounded'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, outer_eye_style: 'rounded' });
													}}
												>
													Rounded
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.outer_eye_style === 'flower'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, outer_eye_style: 'flower' });
													}}
												>
													Flower
												</FlatButton>
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper className={classes.paper} elevation={0}>
												<FlatButton
													className={
														qrprops?.outer_eye_style === 'leaf'
															? classes.customizeButtonFocused
															: classes.customizeButton
													}
													variant="contained"
													color="secondary"
													onClick={() => {
														setQRProps({ ...qrprops, outer_eye_style: 'leaf' });
													}}
												>
													Leaf
												</FlatButton>
											</Paper>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
									className={classes.accordionSummry}
								>
									<i className="fa-solid fa-palette"></i>
									<Typography className={classes.heading}>Select Color</Typography>
								</AccordionSummary>
								<Box>
									<Typography
										className={classes.title}
										style={{
											width: '100%',
											textAlign: 'left',
										}}
									>
										<i className="fa-solid fa-paint-roller"></i> Foreground type
									</Typography>
								</Box>
								<AccordionDetails>
									<Box style={{ width: '100%' }}>
										<Grid container spacing={3}>
											<Grid item xs={6}>
												<Paper className={classes.paper} elevation={0}>
													<FlatButton
														className={
															qrprops?.foreground_type !== 'gradient'
																? classes.customizeButtonFocused
																: classes.customizeButton
														}
														variant="contained"
														color="secondary"
														onClick={(e) => {
															setQRProps({ ...qrprops, foreground_type: 'color' });
														}}
													>
														Color
													</FlatButton>
												</Paper>
											</Grid>
											<Grid item xs={6}>
												<Paper className={classes.paper} elevation={0}>
													<FlatButton
														className={
															qrprops?.foreground_type &&
															qrprops?.foreground_type === 'gradient'
																? classes.customizeButtonFocused
																: classes.customizeButton
														}
														variant="contained"
														color="secondary"
														onClick={(e) => {
															setQRProps({
																...qrprops,
																foreground_type: 'gradient',
																foreground_gradient_style: 'horizontal',
																foreground_gradient_one:
																	qrprops.foreground_gradient_one ?? '#000000',
																foreground_gradient_two: '#000000',
															});
														}}
													>
														Gradient
													</FlatButton>
												</Paper>
											</Grid>
										</Grid>

										<Box>
											{qrprops?.foreground_type && qrprops?.foreground_type === 'gradient' && (
												<>
													<Box className={classes.dropDownSelect}>
														<Box className={classes.marginTopBottom}>
															<Typography style={{ width: '100%', textAlign: 'left' }}>
																<i className="fa-solid fa-brush"></i> style
															</Typography>
														</Box>

														<Select
															name="selectedGradientStyle"
															fullWidth
															variant="outlined"
															value={
																qrprops?.foreground_gradient_style
																	? qrprops?.foreground_gradient_style
																	: 'horizontal'
															}
															label="Foreground gradient style"
															onChange={(e) => {
																setQRProps({
																	...qrprops,
																	foreground_gradient_style: String(e.target.value),
																});
															}}
														>
															<option value="vertical">Vertical</option>
															<option value="horizontal">Horizontal</option>
															<option value="diagonal">Diagonal</option>
															<option value="inverse_diagonal">Inverse Diagonal</option>
															<option value="radial">Radial</option>
														</Select>
													</Box>

													<Box className={(classes.colorBox, classes.marginTopBottom)}>
														{/** Color Pickers */}

														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({
																	...qrprops,
																	foreground_gradient_one: color,
																});
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="primaryColor"
															name="customQrCodeColors.primary"
															label="Foreground Primary Color"
															color={qrprops.foreground_gradient_one ?? '#000000'}
														/>
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({
																	...qrprops,
																	foreground_gradient_two: color,
																});
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="secondaryColor"
															name="customQrCodeColors.secondary"
															label="Foreground Secondary Color"
															color={qrprops.foreground_gradient_two ?? '#000000'}
														/>
													</Box>
												</>
											)}

											{qrprops?.foreground_type && qrprops?.foreground_type === 'color' && (
												<Box className={(classes.colorBox, classes.marginTopBottom)}>
													<QRColorPickerPopover
														setColor={(color) => {
															setQRProps({
																...qrprops,
																foreground_color: color,
															});
														}}
														setError={(e) => {
															console.log(e);
														}}
														id="foreground_color"
														name="foreground_color"
														label="Foreground Color"
														color={qrprops?.foreground_color ?? '#FFFFFF'}
													/>
												</Box>
											)}
											<Box className={(classes.colorBox, classes.marginTopBottom)}>
												{/** Background Color Picker */}

												<QRColorPickerPopover
													setColor={(color) => {
														setQRProps({
															...qrprops,
															background_color: color,
														});
													}}
													setError={(e) => {
														console.log(e);
													}}
													id="backgroundColor"
													name="customQrCodeColors.bgColor"
													label="Background Color"
													color={qrprops?.background_color ?? '#FFFFFF'}
												/>
											</Box>

											<Box>
												<div className={classes.sliderDisplayInfo}>
													<Typography
														style={{
															width: 'auto',
															textAlign: 'left',
															marginLeft: -10,
														}}
													>
														<i className="fa-solid fa-lightbulb"></i> transparency
													</Typography>
													<p>{qrprops?.background_color_transparency ?? 0}%</p>
												</div>
												<Slider
													value={Number(qrprops?.background_color_transparency) ?? 0}
													onChange={handleBgColorTransparencyChange}
												/>
											</Box>

											<Box className={(classes.marginTopBottom, classes.switch)}>
												<Typography style={{ width: '90%', textAlign: 'justify' }}>
													<i className="fa-solid fa-eye"></i> Custom eyes color
												</Typography>
												<Switch
													checked={qrprops?.custom_eyes_color === 'on' ? true : false}
													name="pickEyeColor"
													onChange={(e) => {
														if (e.target.checked) {
															setQRProps({
																...qrprops,
																custom_eyes_color: 'on',
															});
														} else {
															setQRProps({
																...qrprops,
																custom_eyes_color: 'off',
																eyes_inner_color: '#000000',
																eyes_outer_color: '#000000',
															});
														}
													}}
													inputProps={{ 'aria-label': 'eye color switch' }}
												/>
												{qrprops?.custom_eyes_color === 'on' && (
													<Box className={(classes.colorBox, classes.marginTopBottom)}>
														{/**
														 * Eye Color Pickers
														 */}
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({ ...qrprops, eyes_inner_color: color });
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="innerEyeColor"
															name="qrprops?.eyes_inner_color"
															label="Inner Eye Color"
															color={qrprops?.eyes_inner_color ?? '#000000'}
														/>
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({ ...qrprops, eyes_outer_color: color });
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="outerEyeColor"
															name="customQrCodeColors.secondaryEyeColor"
															label="Outer Eye Color"
															color={qrprops?.eyes_outer_color ?? '#000000'}
														/>
													</Box>
												)}
											</Box>
										</Box>
									</Box>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
									className={classes.accordionSummry}
								>
									<i className="fa-solid fa-crop-simple"></i>{' '}
									<Typography className={classes.heading}>Select Frame</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box style={{ width: '100%' }}>
										{/* GROUP OF FRAMES GO HERE */}
										<Grid container spacing={1}>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === ''
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															delete qrprops.frame;
															setQRProps({ ...qrprops });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 100'%3E%3Ctext x='150' y='60' font-family='Arial, sans-serif' font-size='36' text-anchor='middle' fill='%23333333'%3ENo Frame%3C/text%3E%3C/svg%3E"
															alt="'No Frame'"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'round_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'round_bottom_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 28' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M22.7,0H1.3C0.6,0,0,0.6,0,1.3v25.3C0,27.4,0.6,28,1.3,28h21.3c0.7,0,1.3-0.6,1.3-1.3V1.3C24,0.6,23.4,0,22.7,0 z M23,22c0,0.6-0.5,1-1,1H2c-0.6,0-1-0.5-1-1V2c0-0.6,0.5-1,1-1h20c0.6,0,1,0.5,1,1V22z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Round Bottom Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'round_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'round_top_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 28' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M1.3,28L22.6,28c0.7,0,1.3-0.6,1.3-1.3L24,1.4c0-0.7-0.6-1.3-1.3-1.3L1.4,0C0.7,0,0.1,0.6,0,1.3L0,26.6 C-0.1,27.4,0.5,28,1.3,28z M1,6c0-0.6,0.5-1,1-1L22,5c0.6,0,1,0.5,1,1L23,26c0,0.6-0.5,1-1,1L2,27c-0.6,0-1-0.5-1-1L1,6z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Round Top Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({
																...qrprops,
																frame: 'tooltip_bottom_text',
															});
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 30' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M1.3,24l21.3,0c0.7,0,1.3-0.6,1.3-1.3l0-21.3C24,0.6,23.4,0,22.7,0L1.3,0C0.6,0,0,0.6,0,1.3l0,21.3 C0,23.4,0.6,24,1.3,24z M1,2c0-0.6,0.5-1,1-1l20,0c0.6,0,1,0.5,1,1v20c0,0.6-0.5,1-1,1L2,23c-0.6,0-1-0.5-1-1V2z'/%3E%3Cpath d='M1,30h22c0.5,0,1-0.4,1-1v-3c0-0.5-0.4-1-1-1H13l-1-1l-1,1H1c-0.5,0-1,0.4-1,1v3C0,29.6,0.4,30,1,30z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Bottom Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'tooltip_top_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 30' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M22.7,6L1.3,6C0.6,6,0,6.6,0,7.3l0,21.3C0,29.4,0.6,30,1.3,30l21.3,0c0.7,0,1.3-0.6,1.3-1.3l0-21.3 C24,6.6,23.4,6,22.7,6z M23,28c0,0.6-0.5,1-1,1L2,29c-0.6,0-1-0.5-1-1V8c0-0.6,0.5-1,1-1l20,0c0.6,0,1,0.5,1,1V28z'/%3E%3Cpath d='M23,0H1C0.4,0,0,0.4,0,1v3c0,0.5,0.4,1,1,1h10l1,1l1-1h10c0.5,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Top Text"
														/>
													</div>
												</Paper>
											</Grid>

											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'ribbon_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'ribbon_bottom_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 26.4' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M24,21h-1.7V1.7H1.7V21H0l1,2l-1,2h1v2h22v-2h1l-1-2L24,21z M2,2h20v19v1H2v-1V2z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Ribbon Bottom Text"
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'ribbon_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({ ...qrprops, frame: 'ribbon_top_text' });
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 26.4' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cpath d='M0,6h1.7v19.3h20.7V6H24l-1-2l1-2h-1V0H1v2H0l1,2L0,6z M22,25H2V6V5h20v1V25z'/%3E%3C/g%3E%3C/svg%3E"
															alt="Ribbon Top Text"
															style={{ objectFit: 'contain' }}
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_snap_bottom_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({
																...qrprops,
																frame: 'tooltip_snap_bottom_text',
															});
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 500 705' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cg transform='matrix(2.399191, 0, 0, 2.399191, -109.938606, -7.37865)' style=''%3E%3Cpath d='M 225.107 7.755 L 244.497 7.755 C 247.258 7.755 249.497 9.994 249.497 12.755 L 249.497 31.485 L 254.227 31.485 L 254.227 12.755 C 254.227 7.409 249.893 3.075 244.547 3.075 L 225.107 3.075 L 225.107 7.755 Z'/%3E%3Cpath d='M 50.393 31.485 L 50.393 12.755 C 50.393 9.994 52.632 7.755 55.393 7.755 L 73.463 7.755 L 73.463 3.075 L 55.393 3.075 C 50.047 3.075 45.713 7.409 45.713 12.755 L 45.713 31.485 L 50.393 31.485 Z'/%3E%3Cpath d='M 73.573 206.799 L 55.503 206.799 C 52.742 206.799 50.503 204.56 50.503 201.799 L 50.503 183.069 L 45.823 183.069 L 45.823 201.799 C 45.823 207.145 50.157 211.479 55.503 211.479 L 73.573 211.479 L 73.573 206.799 Z'/%3E%3Cpath d='M 249.547 183.069 L 249.547 201.799 C 249.547 204.56 247.308 206.799 244.547 206.799 L 225.157 206.799 L 225.157 211.479 L 244.547 211.479 C 249.893 211.479 254.227 207.145 254.227 201.799 L 254.227 183.069 L 249.547 183.069 Z'/%3E%3Cpath d='M 244.68 214.77 L 55.38 214.77 C 50.272 214.77 46.13 218.911 46.13 224.02 L 46.13 265.58 C 46.125 270.692 50.268 274.84 55.38 274.84 L 125.93 274.84 C 126.544 274.837 127.134 275.078 127.57 275.51 L 148.31 296.25 C 149.219 297.15 150.682 297.15 151.59 296.25 L 172.34 275.51 C 172.774 275.075 173.366 274.833 173.98 274.84 L 244.56 274.84 C 249.673 274.84 253.816 270.692 253.81 265.58 L 253.81 224.02 C 253.849 218.942 249.758 214.797 244.68 214.77 Z' style='transform-box: fill-box; transform-origin: 50%25 50%25;' transform='matrix(-1, 0, 0, -1, -0.000006, -0.000011)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Snap Bottom Text"
															style={{ overflow: 'hidden' }}
														/>
													</div>
												</Paper>
											</Grid>
											<Grid item xl={4}>
												<Paper className={classes.paper} elevation={1}>
													<div
														className={
															qrprops?.frame === 'tooltip_snap_top_text'
																? classes.frameDisplayBoxFocused
																: classes.frameDisplayBox
														}
														onClick={(e) => {
															setQRProps({
																...qrprops,
																frame: 'tooltip_snap_top_text',
															});
														}}
													>
														<img
															src="data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 500 705' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='scale(1) translate(0 0)'%3E%3Cg transform='matrix(2.399191, 0, 0, 2.399191, -109.938606, -7.37865)' style=''%3E%3Cpath d='M224.88,93.12h19.39a5,5,0,0,1,5,5v18.73H254V98.12a9.68,9.68,0,0,0-9.68-9.68H224.88Z'/%3E%3Cpath d='M50.73,116.85V98.12a5,5,0,0,1,5-5H73.8V88.44H55.73a9.68,9.68,0,0,0-9.68,9.68v18.73Z'/%3E%3Cpath d='M73.8,291.67H55.73a5,5,0,0,1-5-5V267.94H46.05v18.73a9.68,9.68,0,0,0,9.68,9.68H73.8Z'/%3E%3Cpath d='M249.27,267.94v18.73a5,5,0,0,1-5,5H224.88v4.68h19.39a9.68,9.68,0,0,0,9.68-9.68V267.94Z'/%3E%3Cpath d='M244.75,3.65H55.45A9.25,9.25,0,0,0,46.2,12.9V54.46a9.25,9.25,0,0,0,9.25,9.26H126a2.32,2.32,0,0,1,1.64.67l20.74,20.74a2.33,2.33,0,0,0,3.28,0l20.75-20.74a2.28,2.28,0,0,1,1.64-.67h70.58a9.25,9.25,0,0,0,9.25-9.26V12.9A9.18,9.18,0,0,0,244.75,3.65Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
															alt="Tooltip Snap Top Text"
														/>
													</div>
												</Paper>
											</Grid>
										</Grid>
										<Box className={classes.marginTopBottom}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-signature"></i> Frame text
												</Typography>
											</Box>
											<TextField
												fullWidth
												label="Frame Text"
												id="outlined-size-small"
												placeholder="Enter text here"
												variant="outlined"
												size="small"
												value={frameTextTemp}
												onChange={(e) => {
													setFrameTextTemp(e.target.value);
												}}
												onBlur={(e) => {
													setQRProps({
														...qrprops,
														frame_text: frameTextTemp,
														frame_text_size: qrprops?.frame_text_size
															? qrprops?.frame_text_size
															: 0,
													});
												}}
												onKeyDown={(e) => {
													if (e.key === 'Enter') {
														setQRProps({ ...qrprops, frame_text: frameTextTemp });
													}
												}}
											/>
										</Box>

										<Box className={classes.marginTopBottom}>
											<div className={classes.sliderDisplayInfo}>
												<Typography
													style={{ width: 'auto', marginLeft: -10, textAlign: 'left' }}
												>
													<i className="fa-solid fa-arrows-up-down"></i> Frame text size
												</Typography>
												<p>{qrprops?.frame_text_size ? qrprops?.frame_text_size : 0}</p>
											</div>

											<Slider
												min={-5}
												max={5}
												value={qrprops?.frame_text_size ? Number(qrprops?.frame_text_size) : 0}
												onChange={handleFrameTextSizeChange}
											/>
										</Box>
										{/* TEXT FONT CHOICES GO HERE */}
										<Box className={classes.marginTopBottom}>
											<Typography style={{ width: '90%', textAlign: 'left' }}>
												<i className="fa-solid fa-pen-nib"></i>
												Frame text font
											</Typography>

											<Grid container spacing={3}>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'times_new_roman'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'times_new_roman',
																});
															}}
															style={{
																fontFamily: 'Times New Roman',
															}}
														>
															Times New Roman
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'georgia'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'georgia',
																});
															}}
															style={{
																fontFamily: 'Georgia',
															}}
														>
															Georgia
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'courier'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'courier',
																});
															}}
															style={{
																fontFamily: 'Courier',
															}}
														>
															Courier
														</FlatButton>
													</Paper>
												</Grid>

												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'arial'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'arial',
																});
															}}
															style={{
																fontFamily: 'Arial',
															}}
														>
															Arial
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'helvetica'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'helvetica',
																});
															}}
															style={{
																fontFamily: 'Helvetica',
															}}
														>
															Helvetica
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'verdana'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'verdana',
																});
															}}
															style={{
																fontFamily: 'Verdana',
															}}
														>
															Verdana
														</FlatButton>
													</Paper>
												</Grid>

												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'tahoma'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'tahoma',
																});
															}}
															style={{
																fontFamily: 'Tahoma',
															}}
														>
															Tahoma
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'trebuchet_ms'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'trebuchet_ms',
																});
															}}
															style={{
																fontFamily: 'Trebuchet Ms',
															}}
														>
															Trebuchet ms
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'courier_new'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'courier_new',
																});
															}}
															style={{
																fontFamily: 'Courier New',
															}}
														>
															Courier new
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'lucida_console'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'lucida_console',
																});
															}}
															style={{
																fontFamily: 'Lucida Console',
															}}
														>
															Lucida Console
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'monaco'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'monaco',
																});
															}}
															style={{
																fontFamily: 'Monaco',
															}}
														>
															Monaco
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'comic_sans_ms'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'comic_sans_ms',
																});
															}}
															style={{
																fontFamily: 'Comic Sans MS',
															}}
														>
															Comic Sans Ms
														</FlatButton>
													</Paper>
												</Grid>
												<Grid item xs={12} md={6} lg={4}>
													<Paper className={classes.paper} elevation={0}>
														<FlatButton
															// size="large"
															className={
																qrprops?.frame_text_font === 'impact'
																	? classes.customizeButtonFocused
																	: classes.customizeButton
															}
															variant="contained"
															color="secondary"
															onClick={() => {
																setQRProps({
																	...qrprops,
																	frame_text_font: 'impact',
																});
															}}
															style={{
																fontFamily: 'Impact',
															}}
														>
															Impact
														</FlatButton>
													</Paper>
												</Grid>
											</Grid>
										</Box>
										<Box className={(classes.marginTopBottom, classes.switch)}>
											<Typography style={{ width: '90%', textAlign: 'left' }}>
												<i className="fa-solid fa-droplet"></i> Apply custom colors
											</Typography>

											<Switch
												checked={
													qrprops?.custom_frame_colors ? qrprops.custom_frame_colors : false
												}
												name="pickFrameColor"
												onChange={(e) => {
													if (e.target.checked === false) {
														setQRProps({
															...qrprops,
															frame_color: '',
															frame_text_color: '',
															custom_frame_colors: e.target.checked,
														});
													} else {
														setQRProps({
															...qrprops,
															custom_frame_colors: e.target.checked,
														});
													}
												}}
												inputProps={{ 'aria-label': 'frame color switch' }}
											/>
											{qrprops?.custom_frame_colors === true && (
												<>
													<Box className={(classes.colorBox, classes.marginTopBottom)}>
														{/**
														 * Eye Color Pickers
														 */}
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({ ...qrprops, frame_color: color });
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="frameColor"
															name="customQrCodeColors.frameColor"
															label="Frame color"
															color={
																qrprops.foreground_color
																	? qrprops.foreground_color
																	: qrprops?.frame_color ?? '#000000'
															}
														/>
													</Box>
													<Box className={(classes.colorBox, classes.marginTopBottom)}>
														<QRColorPickerPopover
															setColor={(color) => {
																setQRProps({ ...qrprops, frame_text_color: color });
															}}
															setError={(e) => {
																console.log(e);
															}}
															id="frameTextColor"
															name="customQrCodeColors.frameTextColor"
															label="Frame text color"
															color={
																qrprops?.background_color
																	? qrprops?.background_color
																	: qrprops?.frame_text_color ?? '#FFFFFF'
															}
														/>
													</Box>
												</>
											)}
										</Box>
									</Box>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
									className={classes.accordionSummry}
								>
									<i className="fa-solid fa-copyright"></i>{' '}
									<Typography className={classes.heading}>Select Branding</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box style={{ width: '100%' }}>
										<div className={(classes.marginTopBottom, classes.switch)}>
											<Typography style={{ display: 'inline' }}>
												<i className="fa-regular fa-eye"></i> Show logo
											</Typography>

											<Switch
												style={{ display: 'inline' }}
												checked={qrprops.qr_code_logo_activate === 'true'}
												name="qr_code_logo_activate"
												onChange={(e) => {
													if (e.target.checked) {
														setQRProps({
															...qrprops,
															qr_code_logo_activate: 'true',
															qr_code_logo:
																'https://storage.googleapis.com/liii/locationImages/7148c3e5b85c83e51f65f81432cea0fbxv3DmXMSsBuBjBNbnmhZ.png',
														});
													} else {
														setQRProps({
															...qrprops,
															qr_code_logo_activate: 'false',
															qr_code_logo: '',
														});
													}
												}}
												inputProps={{ 'aria-label': 'Show logo' }}
											/>
										</div>
										{qrprops.qr_code_logo_activate === 'true' && (
											<Box>
												<div style={{ cursor: 'pointer' }}>
													<CustomImageUploading
														imageUrl={
															(qrCodeLogo && URL.createObjectURL(qrCodeLogo)) ??
															qrprops.qr_code_logo
														}
														setImagePreview={(file: File) => {
															// console.log("Background uploaded",file)
															setQrCodeLogo(file);
															// setQRProps({
															// 	...qrprops,
															// 	qr_code_logo_activate: true,
															// 	});
														}}
														isDrag={isDrag}
													/>
												</div>
												<div className={classes.sliderDisplayInfo}>
													<Typography
														style={{
															width: 'auto',
															textAlign: 'left',
															marginLeft: -10,
														}}
													>
														<i className="fa-solid fa-arrows-up-down-left-right"></i> Logo
														size
													</Typography>
													<p>{qrprops?.qr_code_logo_size ?? 5}</p>
												</div>

												<Slider
													min={5}
													max={35}
													value={Number(qrprops?.qr_code_logo_size)}
													onChange={handleLogoSizeChange}
												/>
											</Box>
										)}
										{/* BACKGROUND IMAGE UPLOAD GO HERE */}
										<>
											<Box style={{ width: '100%' }}></Box>
											<div className={(classes.marginTopBottom, classes.switch)}>
												<Typography style={{ display: 'inline' }}>
													<i className="fa-solid fa-image"></i> Background Image
												</Typography>

												<Switch
													style={{ display: 'inline' }}
													checked={qrprops.qr_code_background_activate === 'true'}
													name="qr_code_background_activate"
													onChange={(e) => {
														if (e.target.checked) {
															setQRProps({
																...qrprops,
																qr_code_background_activate: 'true',
															});
														} else {
															setQRProps({
																...qrprops,
																qr_code_background: '',
																qr_code_background_activate: 'false',
															});
														}
													}}
													inputProps={{ 'aria-label': 'Show background' }}
												/>
											</div>
											{qrprops.qr_code_background_activate === 'true' && (
												<>
													<Box style={{ marginBottom: 10, marginTop: 10, cursor: 'pointer' }}>
														<CustomImageUploading
															imageUrl={
																qrprops.qr_code_background ??
																(qrCodeBackground &&
																	URL.createObjectURL(qrCodeBackground))
															}
															setImagePreview={(file: File) => {
																// console.log("Background uploaded",file)
																setQrCodeBackground(file);
															}}
															isDrag={isDrag}
														/>
													</Box>
													<Box>
														<div className={classes.sliderDisplayInfo}>
															<Typography style={{ width: 'auto', textAlign: 'left' }}>
																Background image transparency
															</Typography>
															<p>
																{Number(qrprops?.qr_code_background_transparency) ?? 0}%
															</p>
														</div>

														<Slider
															value={
																Number(qrprops?.qr_code_background_transparency) ?? 0
															}
															onChange={handleBgImgTransparencyChange}
														/>
													</Box>
												</>
											)}
										</>
									</Box>
								</AccordionDetails>
							</Accordion>
							<Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel2a-content"
									id="panel2a-header"
									className={classes.accordionSummry}
								>
									<i className="fa-solid fa-screwdriver-wrench"></i>{' '}
									<Typography className={classes.heading}> Options</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box style={{ width: '100%' }}>
										<Box>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-maximize"></i> Size
												</Typography>
											</Box>

											{/* Size input */}
											<TextField
												fullWidth
												id="size"
												type="number"
												name="size"
												placeholder='Enter overall size here, e.g "500"'
												value={qrprops?.size ? qrprops?.size : ''}
												onChange={(e) => {
													setQRProps({ ...qrprops, size: Number(e.target.value) });
												}}
												variant="outlined"
											></TextField>
										</Box>
										{/* BACKGROUND IMAGE UPLOAD GO HERE */}
										<Box>
											{/* Margin Size input */}
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: 'auto', textAlign: 'left' }}>
													<i className="fa-solid fa-expand"></i> Margin Size
												</Typography>
											</Box>
											<TextField
												fullWidth
												id="size"
												type="number"
												name="size"
												placeholder="Enter margin size here"
												value={qrprops?.margin ? qrprops?.margin : ''}
												variant="outlined"
												onChange={(e) => {
													setQRProps({ ...qrprops, margin: Number(e.target.value) });
												}}
											></TextField>
										</Box>
										<Box className={classes.dropDownSelect}>
											<Box className={classes.marginTopBottom}>
												<Typography style={{ width: '100%', textAlign: 'left' }}>
													<i className="fa-solid fa-check"></i> Error correction capability
												</Typography>
											</Box>

											<Select
												fullWidth
												name="errorCorrectionCapability"
												value={qrprops?.ecc ? qrprops?.ecc : 'L'}
												label=" Error correction capability "
												variant="outlined"
												onChange={handleEcc}
											>
												<MenuItem value="L">Low (7%)</MenuItem>
												<MenuItem value="M">Medium (15%)</MenuItem>
												<MenuItem value="Q">Quartile (24%)</MenuItem>
												<MenuItem value="H">High (30%)</MenuItem>
											</Select>
										</Box>
									</Box>
								</AccordionDetails>
							</Accordion>
						</Box>
						<div className={classes.dismissableTip}>
							{openDialog && (
								<LiiingoDismissableTip name={CLOSED_QR_HOVER}>
									<Typography variant="body2">
										Hover on the logo in the QR code preview, then click the trash icon to revert to
										the default logo.
									</Typography>
								</LiiingoDismissableTip>
							)}
						</div>
					</Box>
				</Box>
				{/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}

				{/* <QRCodeDesigner /> */}
			</Box>
			<Box className={classes.buttonBox}>
				<FlatButton className={classes.button} onClick={() => handleOpen(false)}>
					Cancel
				</FlatButton>
				<FlatButton
					// disabled={primaryError || secondaryError}
					className={classes.button}
					variant="contained"
					color="primary"
					onClick={handleSave}
				>
					Save
				</FlatButton>
			</Box>
			{/* </Box> */}
		</LiiingoTempDrawer>
	);
};
