import { PHP_ACCESS_TOKEN } from '../util/constants';
import * as api from '../store/api-client';

export const useAuth = () => {
	const login = async (email, password) => {
		// now that the password is in sync, we can log in with the php api
		const token = await api.login(email, password);
		if (token) {
			localStorage.setItem(PHP_ACCESS_TOKEN, token);
		}

		return token; // note: if we didn't get a token back, we're returning a falsey value. This is intentional, as we want to return false if the login failed
	};

	const logout: () => void = async () => {
		localStorage.removeItem(PHP_ACCESS_TOKEN);
		// reloading will wipe the store and reroute to the login page automatically
		window.location.reload();
	};

	return { login, logout };
};
