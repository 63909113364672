// This plugin adds a Bootstrap grid insertion feature to TinyMCE
// It allows users to create customizable grid layouts with specified rows, columns, and responsiveness
const GridLayoutPlugin = (editor) => {
	// Register a custom SVG icon for the grid layout button
	// This icon visually represents a grid layout in the editor toolbar
	editor.ui.registry.addIcon(
		'grid-layout',
		'<svg width="24" height="24" viewBox="0 0 24 24"><path d="M3 3h18v18H3V3zm16 16V5H5v14h14zM7 7h4v4H7V7zm0 6h4v4H7v-4zm6-6h4v4h-4V7zm0 6h4v4h-4v-4z" fill="currentColor"/></svg>'
	);

	// Add a new button to the editor toolbar for grid insertion
	editor.ui.registry.addButton('gridlayout', {
		icon: 'grid-layout', // Use the custom icon we just registered
		tooltip: 'Insert Columns', // Tooltip text shown on hover
		onAction: function () {
			// This function is called when the button is clicked
			// It opens a dialog for the user to input grid parameters
			editor.windowManager.open({
				title: 'Insert Columns',
				size: 'small',
				body: {
					type: 'panel',
					items: [
						// Input field for the number of rows in the grid
						{
							type: 'htmlpanel',
							html: `
                                <div class="mb-3">
									<p>Select number of columns</p>
								</div>
                            `,
						},
						// Input field for the number of columns in the grid
						{
							type: 'input',
							name: 'columns',
							label: 'Number of Columns',
							inputMode: 'numeric',
							placeholder: 'Enter number of columns',
						},
					],
				},
				buttons: [
					{
						type: 'cancel',
						text: 'Cancel',
					},
					{
						type: 'submit',
						text: 'Next',
						primary: true,
					},
				],
				onSubmit: function (api) {
					// This function is called when the user submits the first dialog
					var data = api.getData();
					var cols = parseInt(data.columns);

					// Open a second dialog for setting individual column widths
					editor.windowManager.open({
						title: 'Set Column Widths',
						body: {
							type: 'panel',
							// Dynamically create a select box for each column
							items: [
								{
									type: 'htmlpanel', // an HTML panel component
									html: `
									<div class="mb-3">
                                        <ol>
                                                <li>Column widths: Auto = equal width</li>
                                                <li> Or choose 1-12 (total must = 12) </li>
                                                <li>Example: 2 Columns. 
                                                    <ul>
                                                        <li>Left = 4</li>
                                                        <li>Right = 8</li>
                                                        <li>This makes Right column twice as wide</li>
                                                    <ul>
                                                </li>
                                        </ol>
                                    </div>
									`,
								},

								...Array.from({ length: cols }, (_, index) => ({
									type: 'selectbox',
									name: 'col' + index,
									label:
										index === 0
											? 'Left Column '
											: index + 1 === cols
											? 'Right Column '
											: 'Middle Column ' + index ,
									// Options for column widths, based on Bootstrap's 12-column system
									items: [
										{ text: 'Auto', value: '' },
										{ text: '1 ', value: '1' },
										{ text: '2 ', value: '2' },
										{ text: '3 ', value: '3' },
										{ text: '4 ', value: '4' },
										{ text: '5 ', value: '5' },
										{ text: '6 ', value: '6' },
										{ text: '7 ', value: '7' },
										{ text: '8 ', value: '8' },
										{ text: '9 ', value: '9' },
										{ text: '10 ', value: '10' },
										{ text: '11 ', value: '11' },
										{ text: '12 ', value: '12' },
									],
								})),
							],
						},
						buttons: [
							{
								type: 'cancel',
								text: 'Cancel',
							},
							{
								type: 'submit',
								text: 'Insert',
								primary: true,
							},
						],
						onSubmit: function (api) {
							// This function is called when the user submits the second dialog
							var colWidths = api.getData();

							// Start building the HTML for the grid
							// Use container-fluid for responsive grids, otherwise use container
							// Generate HTML for each row and column in the grid
							var gridHtml = '  <div class="row g-0 p-0 m-0">\n'; // g-0 removes gutters
							for (var j = 0; j < cols; j++) {
								// Determine the appropriate Bootstrap class for the column
								var colClass = 'col';
								if (colWidths['col' + j]) {
									colClass += '-' + colWidths['col' + j];
								}
								// Add a div for the column with a placeholder for content
								gridHtml +=
									'    <div class="' +
									colClass +
									' p-0 m-0">\n      <div>Content goes here</div>\n    </div>\n';
							}
							gridHtml += '  </div>\n';

							// Insert the generated HTML into the editor at the current cursor position
							editor.insertContent(gridHtml);
							api.close(); // Close the dialog
						},
					});

					api.close(); // Close the first dialog
				},
			});
		},
	});
};

export default GridLayoutPlugin;
