import axios from 'axios';
import axiosInstance, { stripQuotes } from '.';

// token is good for 2 days
export const login = (email: string, password: string) => {
	console.log('login');
	return axios
		.post(
			process.env.REACT_APP_LIIINGO_URL + '/auth/login',
			{
				email: email,
				password: password,
			},
			{
				responseType: 'text',
			}
		)
		.then((res) => stripQuotes(res.data));
};

// token is good for 2 days
export const refreshToken = (authToken: string) => {
	console.log('refresh token');
	return axios
		.post(
			process.env.REACT_APP_LIIINGO_URL + '/auth/refresh',
			{},
			{
				responseType: 'text',
				headers: { Authorization: `Bearer ${authToken}` },
			}
		)
		.then((res) => stripQuotes(res.data));
};

export const startImpersonation = (organizationId: string) => {
	return axiosInstance
		.post('/organization/impersonateStart', {
			id: organizationId,
		})
		.then((res: any) => res.data);
};

export const stopImpersonation = () => {
	return axiosInstance.post('/organization/impersonateStop', {}).then((res: any) => res.data);
};

export const getUIVersion = () => {
	return axiosInstance.post('/auth/getUIVersion').then((res: any) => res.data);
};
