import Box from '@mui/material/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { _qrLogo } from '../../store/slices/locationSlice';
import { _selectedTopic, _topicIsLoading } from '../../store/slices/topicSlice';
import { FlatButton } from '../Buttons/FlatButton';
import { liiingoSuccess, LiiingoSuccessSnackbar } from '../LiiingoSuccessSnackbar';
import { LiiingoWarning } from '../LiiingoWarning';
import { Throbber } from '../Throbber';
import { CardTitle } from './CardComponents/CardTitle';
import { QRContextMenu } from './CardComponents/Menu/QRContextMenu';
import { QRMenu } from './CardComponents/Menu/QRMenu';
import { QREditor } from './QREditor/QREditor';
import { QRLoader } from './QRLoader';
import { getZip } from '../../util/getZip';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		skeleton: {
			margin: '20px auto',
		},
		formCard: {
			marginBottom: 20,
			backgroundColor: theme.palette.background.paper,
			width: 500,
		},
		spinnerBox: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: 247,
		},
		content: {
			width: 250,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		button: {
			height: 32,
			width: 155,
		},
		qrContent: {
			width: 200,
			padding: 8,
			// backgroundColor: colors.grayLight20,
		},
		bigBox: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		contentBox: {
			width: 500,
			height: 239,
			display: 'flex',
			marginBottom: 8,
		},
		titleBox: {
			display: 'flex',
			alignItems: 'center',
		},
	})
);

export type QRCardProps = {
	loading: boolean;
	qrLinkPath: string;
	primaryColor: string;
	secondaryColor: string;
};

// The QR Card now displays the QrCode from the selected Page, rather than a non-working example QR.
// Get zip/path info from the selectedTopic
export const QRCard = (props: QRCardProps) => {
	const { loading, qrLinkPath, primaryColor, secondaryColor } = props;

	const [openEditor, setOpenEditor] = useState(false);
	const [qrUpdated, setQrUpdated] = useState(false);
	const classes = useStyles();
	const selectedTopic = useAppSelector(_selectedTopic);
	const qrLogo = useAppSelector(_qrLogo);
	const topicIsLoading = useAppSelector(_topicIsLoading);


	//TODO: figure out when to trigger warning for QR colors being too light. This is always false
	const warning = false;

	//this is just for notifying user via toast bar that the QR has been updated
	const handleQrMsg = () => {
		liiingoSuccess('QR Code updated!');
		setQrUpdated(true);
	};

	const handleOpen = () => {
		setOpenEditor(true);
	};

	const Menu = () => (
		<>
			<QRMenu qrLinkPath={qrLinkPath} getZip={()=>getZip(selectedTopic?.id)} />
		</>
	);

	return (
		<Card variant="outlined" className={classes.formCard}>
			{loading ? ( //TODO: skeletonize all content parts individually
				<>
					<Skeleton variant="rectangular" width={450} height={200} className={classes.skeleton} />
					<LiiingoSuccessSnackbar
						open={qrUpdated}
						text="QR Code updated!"
						onClose={() => setQrUpdated(false)}
						autoHide={3000}
					/>
				</>
			) : (
				<QRContextMenu qrLinkPath={qrLinkPath} getZip={() => getZip(selectedTopic?.id)}>
					<Box className={classes.bigBox}>
						<Box className={classes.contentBox}>
							<CardContent className={classes.content}>
								<Box className={classes.titleBox}>
									{warning && (
										<LiiingoWarning message="Your app's QR Code contains a light color that may not scan on a white background." />
									)}
									<CardTitle title="QR Code" menu={Menu} msg="QR Code Options" />
								</Box>
								<Typography variant="body2">
									Create a custom QR code that is recognizable to your audience by adding brand colors
									and a logo.
								</Typography>
								<FlatButton
									className={classes.button}
									variant="contained"
									color="secondary"
									onClick={handleOpen}
								>
									Design QR Code
								</FlatButton>
								<QREditor
									open={openEditor}
									setOpen={(open: boolean) => setOpenEditor(open)}
									handleUpdate={handleQrMsg}
									logo={qrLogo}
									primary={primaryColor}
									secondary={secondaryColor}
								/>
							</CardContent>
							<CardContent className={classes.qrContent}>
								<Throbber isVisible={topicIsLoading} />
								{selectedTopic && <QRLoader topicId={selectedTopic.id} />}
							</CardContent>
						</Box>
					</Box>
				</QRContextMenu>
			)}
		</Card>
	);
};
